import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import styles from './find-us.module.css'
import PageLinks from '../components/page-links'
import GoogleMap from '../components/google-map'

export const FindUsTemplate = ({ page }) => {
  const {
    html,
    frontmatter: { title, location, pageLinks, address, placeId },
  } = page

  let coordinates = null

  if (location && location.startsWith('{')) {
    try {
      const parsedLoc = JSON.parse(location)
      coordinates = parsedLoc.coordinates
    } catch (e) {}
  }

  return (
    <div className="container flex flex-col text-center items-center py-5 lg:py-20">
      <div className="w-full md:w-4/5 lg:w-3/5 xl:w-1/2">
        <h1>{title}</h1>
        <p className="uppercase mt-12">Address</p>
        <a
          className={`${styles.address}`}
          href={`https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${placeId}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {address}
        </a>
        {coordinates ? (
          <div className={`${styles.mapContainer} mt-6`}>
            <GoogleMap
              address={address}
              lat={coordinates[1]}
              lng={coordinates[0]}
            />
          </div>
        ) : (
          ''
        )}
        <div className={`mx-auto mt-2`}>
          <a
            href={`https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${placeId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            Google Maps
          </a>
        </div>
        <div
          className={`mx-auto mt-20`}
          dangerouslySetInnerHTML={{ __html: html }}
        />
      </div>
      <div className="w-full mt-24">
        <PageLinks links={pageLinks} />
      </div>
    </div>
  )
}

const FindUsPage = ({ data }) => {
  return <FindUsTemplate {...data} />
}

FindUsPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.object,
  }),
}

export default FindUsPage

export const pageQuery = graphql`
  query FindUsPage {
    page: markdownRemark(fields: { slug: { eq: "/find-us/" } }) {
      frontmatter {
        title
        location
        placeId
        address
        ...PageLinks
      }
      html
    }
  }
`
