import React, { useCallback } from 'react'
import GoogleMapReact from 'google-map-react'

import mapStyle from '../util/google-map-style.json'

const API_KEY = 'AIzaSyC6fBR-WUNEekjO1sZNzkA35bYwstUwn20' // please be temporary

const createMapOptions = () => ({
  panControl: false,
  mapTypeControl: false,
  scrollwheel: false,
  styles: mapStyle,
})

const GoogleMap = ({ address, lat, lng }) => {
  const isClient = typeof window !== 'undefined'

  const apiIsLoaded = useCallback(
    (map, maps) => {
      new maps.Marker({
        position: { lat, lng },
        map,
        title: address,
      })
    },
    [address, lat, lng]
  )

  return (
    <section className="google-map w-full h-full">
      <div className="map w-full h-full">
        {isClient && (
          <GoogleMapReact
            bootstrapURLKeys={{ key: API_KEY }}
            defaultCenter={[lat, lng]}
            defaultZoom={17}
            options={createMapOptions}
            yesIWantToUseGoogleMapApiInternals
            onGoogleApiLoaded={({ map, maps }) => apiIsLoaded(map, maps)}
          ></GoogleMapReact>
        )}
      </div>
    </section>
  )
}

export default GoogleMap
